import { ChangeEvent, GALLERY_CHANGE_EVENT } from "./gallery";
import {
  SHORT_TEXT_GALLERY_CHANGE_EVENT,
  ShortTextGalleryChangeEvent,
} from "./shorttextgallery";
import { getViewport } from "./utils";

export const scriptUrl = {
  bottom: {
    live: "/stern.de/MoltenBundle.bottom.js",
    preview:
      "https://adctrl.emsmobile.de/molten/preview/stern/MoltenBundle.bottom.js",
  },
  top: {
    live: "/stern.de/MoltenBundle.top.js",
    preview:
      "https://adctrl.emsmobile.de/molten/preview/stern/MoltenBundle.top.js",
  },
};

/**
 * Advertising library from the AdAlliance, named MoltenBundle
 * @see https://help.adalliance.io/home/tagcontrol/
 */
export class AdScript extends HTMLElement {
  get contentType(): string {
    return this.getAttribute("content-type") ?? "";
  }

  get isIndexPage(): boolean {
    return this.getAttribute("is-index-page") === "true";
  }

  get keywords(): string[] {
    return JSON.parse(this.getAttribute("keywords") ?? "[]");
  }

  get name(): string {
    return this.getAttribute("name") ?? "";
  }

  get zone(): string {
    return this.getAttribute("zone") ?? "";
  }

  get userType(): string {
    return this.getAttribute("user-type") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  #executeBottomScripts() {
    this.#loadScript(
      this.#isPreview() ? scriptUrl.bottom.preview : scriptUrl.bottom.live,
    );
    if (!Array.isArray(window.MoltenBundle?.cmd)) return;
    window.MoltenBundle.cmd.push(() => {
      window.MoltenBundle.finalize();
    });
    this.#addGalleryEventListeners(
      "ws-gallery",
      GALLERY_CHANGE_EVENT,
      this.#handleGalleryChange,
    );
    this.#addGalleryEventListeners(
      "ws-short-text-gallery",
      SHORT_TEXT_GALLERY_CHANGE_EVENT,
      this.#handleShortTextGalleryChange,
    );
  }

  #executeScripts() {
    switch (this.name) {
      case "bottom":
        this.#executeBottomScripts();
        break;
      case "top":
        this.#executeTopScripts();
        break;
      default:
        console.log(`Unknown adscript name: ${this.name}`);
        break;
    }
  }

  #resolveWidescreenViewportAsDesktop = (getViewportReturn: string): string => {
    if (getViewportReturn === "widescreen") {
      return "desktop";
    } else {
      return getViewportReturn;
    }
  };

  #executeTopScripts() {
    this.#loadScript(
      this.#isPreview() ? scriptUrl.top.preview : scriptUrl.top.live,
    );
    window.MoltenBundle = window.MoltenBundle || {};
    window.MoltenBundle.cmd = window.MoltenBundle.cmd || [];
    window.MoltenBundle.cmd.push(() => {
      window.MoltenBundle.getClient().setSiteType(
        this.#resolveWidescreenViewportAsDesktop(getViewport()),
      );
      window.MoltenBundle.getClient().setZone(this.zone);
      window.MoltenBundle.getClient().setIsIndexPage(this.isIndexPage);
      window.MoltenBundle.getClient().setKeywords(this.keywords);
      window.MoltenBundle.getClient().addTargetingParameter(
        "ct",
        this.contentType,
      );
      if (this.userType && this.userType === "plus") {
        window.MoltenBundle.getClient().addTargetingParameter("ut", "plus");
      }
    });
  }

  #addGalleryEventListeners(
    elementName: string,
    eventName: string,
    handler: EventListener,
  ) {
    const galleries = document.querySelectorAll(elementName);
    for (const gallery of galleries) {
      gallery.addEventListener(eventName, handler);
    }
  }

  #handleGalleryChange = (event: Event) =>
    this.#handleGalleryEvent(event as ChangeEvent);

  #handleShortTextGalleryChange = (event: Event) =>
    this.#handleGalleryEvent(event as ShortTextGalleryChangeEvent);

  #handleGalleryEvent(event: {
    detail: { adTagIds?: string[]; itemNumber?: string };
  }) {
    const { adTagIds, itemNumber } = event.detail;
    const itemNumberAsNumber =
      itemNumber !== undefined ? parseInt(itemNumber) : undefined;
    if (
      typeof itemNumberAsNumber === "number" &&
      itemNumberAsNumber % 3 === 0
    ) {
      this.#reloadAds(adTagIds);
    }
  }

  #reloadAds(adTagIds?: string[]) {
    window.MoltenBundle.cmd.push(
      window.MoltenBundle.getClient().reloadSpecificAds(adTagIds),
    );
  }

  #isPreview(): boolean {
    return window.location.search.includes("ada=1");
  }

  #loadScript(src: string) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    this.shadowRoot!.appendChild(script);
  }

  connectedCallback() {
    this.#executeScripts();
  }

  disconnectedCallback() {
    this.#removeGalleryEventListeners(
      "ws-gallery",
      GALLERY_CHANGE_EVENT,
      this.#handleGalleryChange,
    );
    this.#removeGalleryEventListeners(
      "ws-short-text-gallery",
      SHORT_TEXT_GALLERY_CHANGE_EVENT,
      this.#handleShortTextGalleryChange,
    );
  }

  #removeGalleryEventListeners(
    elementName: string,
    eventName: string,
    handler: EventListener,
  ) {
    const galleries = document.querySelectorAll(elementName);
    for (const gallery of galleries) {
      gallery.removeEventListener(eventName, handler);
    }
  }
}

"customElements" in window &&
  customElements.get("ws-adscript") === undefined &&
  customElements.define("ws-adscript", AdScript);

declare global {
  interface HTMLElementTagNameMap {
    "ws-adscript": AdScript;
  }
  interface Window {
    MoltenBundle: any;
  }
}
